<template>
    <div class="container">

        <!-- Scan return shipment -->
        <div class="col-md-8 offset-md-2" v-if="!returnShipmentScanned">
            <empty-state
                    icon="scanner"
                    title="Scan a return shipment or enter the return shipment number below">
                <slot>
                    <div class="row">
                        <div class="col">
                            <input v-model="shipmentIdBeingPacked" type="text" class="form-control">
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-success" @click="addReturnShipment()">Add</button>
                        </div>
                    </div>
                </slot>
            </empty-state>
        </div>

        <!-- Kits in return shipment -->
        <template v-if="returnShipmentScanned">

            <!-- Return shipment details -->
            <div class="card card-body card-body-sm">
                <div class="media align-items-center">
                    <div class="media-body">
                        <h5 class="mb-0">Return shipment #1234</h5>
                    </div>
                    <div class="media-right">
                        <router-link :to="{ name: 'clearReturnShipment'}" class="btn btn-danger btn-sm">Change</router-link>
                    </div>
                </div>
            </div>

            <!-- Title -->
            <div class="title">
                <h2>Repair kits in this return shipment</h2>
                <h5 class="text-subtitle">2 of 5 scanned</h5>
            </div>

            <label>Select a repair kit</label>
            <div class="card-grid">
                <div class="card-link-container">
                    <router-link :to="{name: 'repairKitReturns.repairKit'}" class="card card-body card-link justify-content-between flex-row">
                        <div>#1234</div>
                        <div>6 items</div>
                    </router-link>
                </div>
            </div>

            <!-- Empty State -->
            <empty-state
                    emptyIcon
                    icon="box"
                    title="There are no repair kits left to return">
            </empty-state>
        </template>

    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        data() {
            return {
                shipmentIdBeingPacked: 0,
                returnShipmentScanned: false
            }
        },

        methods: {
            ...mapActions([
                'logToApi'
            ]),
            /** Add a repair kit */
            addReturnShipment() {
                this.logToApi({action: 'return_shipping', event: 'start', repairKitId: null, shipmentId: this.shipmentIdBeingPacked});
                this.returnShipmentScanned = true;
            },

            /** Clear a repair kit */
            clearReturnShipment() {
                this.returnShipmentScanned = false;
            }
        }
    }
</script>

<style scoped>

</style>